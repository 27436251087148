<template>
<v-app>
  <Header />
  <div v-if="this.user.loggedIn && this.user.data.claims.role == 'superadmin'">
    <NavMenu />
  </div>
  <v-main>
    <v-container fluid >
      <router-view ></router-view>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import Header from './components/layout/Header'
import NavMenu from './components/layout/NavMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Header,
    NavMenu
  },

  data: () => ({
    drawer: true
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })
  }
}
</script>

<style lang='scss'>
@import '@/sass/_mixins.scss';
@import '@/sass/_common.scss';

.scroll-height {
  height: 100vh;
  overflow-y: scroll;
}

.main-container {
  padding: 0;
  border: 0;
}

.container {
  @include lg-up {
    max-width: 1185px;
  }
}
</style>