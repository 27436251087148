import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import { auth } from '../../fb'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: 'login'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () =>
      import(/* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/admins',
    name: 'admins',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Admins.vue'),
    meta: {
      needLogin: true
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Clients.vue'),
    meta: {
      needLogin: true
    }
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*
// Verify the ID token first.
admin.auth().verifyIdToken(idToken).then(() => {
  if (.admin === true) {
    // Allow access to requested admin resource.
  }
});
*/

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(userAuth => {
    if (userAuth) {
      auth.currentUser.getIdTokenResult()
        .then(function ({
          claims
        }) {
          const needLogin = to.matched.some((record) => record.meta.needLogin)
          if (to.path === '/login' && userAuth) {
            next('dashboard')
          }
          if (needLogin && userAuth && claims.role !== 'superadmin') {
            next('forbidden')
          }
          if (needLogin && !userAuth) {
            next('login')
          } else {
            next()
          }
        })
    } else {
      if (to.matched.some(record => record.meta.needLogin)) {
        next('login')
      } else {
        next()
      }
    }
  })
  next()
})

export default router
