<template>
  <div>
    <h1 class="pb-8">Login</h1>

    <v-divider></v-divider>

    <v-list-item>
      <v-list-item-content>
        <v-form ref="form" @submit.prevent="login">
          <v-card-text>
            <v-text-field v-model="username" label="Usuario">
            </v-text-field>
            <v-text-field
              v-model="password"
              label="Clave"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            >
            </v-text-field>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn color="success" type="submit">
              <v-icon left>mdi-login</v-icon>
              Ingresar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle class="text-center">
          <p>
            Sólo miembros de TappBell<br />
            pueden acceder a la administración remota.
          </p>
          <p>
            ®TappBell es una marca registrada<br />
            <a target="_blank" href="https://tappbell.com"
              >www.TappBell.com</a
            >
            -
            <a target="_blank" href="mailto:info@tappbell.com"
              >info@tappbell.com</a
            >
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { auth } from '../../fb'

export default {
  data () {
    return {
      dialog: true,
      show: false,
      snackbar: {
        visible: false,
        timeout: 2000,
        text: '',
        color: ''
      },
      username: '',
      password: ''
    }
  },
  methods: {
    async login () {
      auth.signInWithEmailAndPassword(this.username, this.password).then(
        () => {
          this.snackbar.text = 'Bienvenido ' + this.username
          this.snackbar.color = 'success'
          this.snackbar.visible = true
          this.$router.push('dashboard')
        },
        (error) => {
          console.error(error)
          this.snackbar.text = 'El usuario o la clave son incorrectos'
          this.snackbar.color = 'error'
          this.snackbar.visible = true
        }
      )
    }
  }
}
</script>

<style></style>
