<template>
  <div class='header'>
    <v-app-bar app elevation="1">
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn icon v-if='user.loggedIn' @click.prevent='signOut'>
        <v-icon>fa-sign-out</v-icon>
      </v-btn>
    </v-app-bar>

  </div>
</template>

<script>
import { auth } from '../../../fb'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    signOut () {
      auth.signOut().then(() => {
        this.$router.push('login')
      })
    }
  }
}
</script>
