import Vue from 'vue'
import Vuex from 'vuex'
// import { db } from '@/fb'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user (state) {
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    }
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        user.getIdTokenResult().then((tokenResult) => {
          const claims = tokenResult.claims
          commit('SET_USER', {
            displayName: user.displayName,
            email: user.email,
            claims: claims
          })
        })
      } else {
        commit('SET_USER', null)
      }
    }
  }
})