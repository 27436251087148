<template>
  <div class='nav-menu'>

    <v-navigation-drawer
      app
      width='200'
      permanent
      height='100vh'
      color='gray darken-4'
      dark
      :mini-variant='mini'
      mini-variant-width='80'
    >

      <v-list nav>
        <v-list-item-icon style="width:100%; justify-content:center;">
          <v-img src='../../assets/logo-tappbell.png' max-width="70"></v-img>
        </v-list-item-icon>
        <v-list-item-group color='white' mandatory>
          <v-list-item
            v-for='item in items'
            :key='item.title'
            router
            :to='item.route'
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: [
        { icon: 'mdi-view-dashboard-outline', title: 'Dashboard', route: '/dashboard' },
        { icon: 'mdi-domain', title: 'Clientes', route: '/clients' },
        { icon: 'mdi-account-star', title: 'Admins', route: '/admins' },
        { icon: 'mdi-account-group', title: 'Usuarios', route: '/users' },
      ]
    }
  },

  computed: {
    mini () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
